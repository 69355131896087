<template>
  <div>
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      :tips="true"
      @selection-change="selectionChange"
    >
      <!-- 表格上部按钮选项卡  -->
      <div
        slot="toolbar"
        style="display:flex;align-items:center;justify-content:space-between;width:100%;"
      >
        <div>
          <el-button @click="openDialog('read')" type="primary" size="mini" v-if="isEdit">设为已读</el-button>
          <el-button @click="openDialog('del')" type="primary" size="mini" v-if="isEdit">删除</el-button>
        </div>
        <el-button @click="isEdit=!isEdit" type="primary" size="mini">{{isEdit?"取消":"编辑"}}</el-button>
      </div>

      <template slot="table-columns">
        <!--  选择框 -->
        <el-table-column type="selection" width="55" v-if="isEdit"></el-table-column>
        <!-- 通知标题 -->
        <el-table-column label="通知标题">
          <template slot-scope="scope">
            <div class="titleBox">
              <span v-if="scope.row.is_read===0"></span>
              <div v-if="scope.row.title">{{scope.row.title}}</div>
              <div v-else>{{scope.row.notice_content.substr(0,4)}}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 发布时间 -->
        <el-table-column label="发布时间" :formatter="MixinUnixToDate" prop="send_time" />
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="openDialog('details',scope.row)" type="text" size="mini">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="dialogContent" v-if="dialogTitle==='通知详情'">
        <h4>{{dialogContentTitle}}</h4>
        <div>{{dialogContent}}</div>
      </div>
      <div class="delBox" v-if="dialogTitle==='删除确认'">删除后无法恢复,确定删除吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" v-if="dialogTitle==='删除确认'">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import {Vue} from 'vue-property-decorator';
import * as API_platformNotification from "@/api/platformNotification";
import { $navbar } from '@/libs/layout/nav-bar/nav-bar.service';

export default {
  name: "activityList",
  data() {
    return {
      //  列表loading状态
      loading: false,
      navbar: $navbar,
      //  是否编辑
      isEdit: false,

      // 弹框显示内容
      centerDialogVisible: false,
      dialogTitle: "",
      dialogContentTitle: "",
      dialogContent: "",

      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
      },

      //  列表数据
      tableData: {},

      //  选中列表行
      chooseRows: [],
    };
  },
  mounted() {
    // 查询店铺站内消息列表
    this.GET_shopShopNoticeLogs();
  },
  activated() {
    // 查询店铺站内消息列表
    this.GET_shopShopNoticeLogs();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_shopShopNoticeLogs();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_shopShopNoticeLogs();
    },

    /**  查询店铺站内消息列表 */
    GET_shopShopNoticeLogs() {
      API_platformNotification.shopShopNoticeLogs(this.params).then((res) => {
        this.tableData = res;
      });
    },

    /**  删除店铺站内消息 */
    DTLETE_shopShopNoticeLogsDel() {
      var ids = "";
      this.chooseRows.forEach((item, index) => {
        if (index === 0) {
          ids += item.id;
        } else {
          ids += "," + item.id;
        }
      })
      API_platformNotification.shopShopNoticeLogsDel(ids).then((res) => {
        this.chooseRows = [];
        this.$message.success("删除成功");
        this.GET_shopShopNoticeLogs();
        console.log(res);
      });
    },

    /**  将消息设置为已读 */
    PUT_shopShopNoticeLogsRead() {
      var ids = "";
      this.chooseRows.forEach((item, index) => {
        if (index === 0) {
          ids += item.id;
        } else {
          ids += "," + item.id;
        }
      })
      API_platformNotification.shopShopNoticeLogsRead(ids).then((res) => {
        this.chooseRows = [];
        this.$message.success("设为已读成功");
        this.GET_shopShopNoticeLogs();
        API_platformNotification.shopShopNoticeWeidu().then(res => {
          this.naver.rightActions[0].badge = res
        })
      });
    },

    openDialog(type, row) {
      if (type === "details") {
        this.dialogTitle = "通知详情";
        this.chooseRows = [];
        this.chooseRows.push(row);
        this.dialogContentTitle = row.type;
        this.dialogContent = row.notice_content;
        this.PUT_shopShopNoticeLogsRead();
      } else if (type === "read") {
        if (this.chooseRows.length === 0) {
          this.$message.error("请选择要设为已读的列表行！");
          return false;
        }
        this.PUT_shopShopNoticeLogsRead();
        return false;
      } else if (type === "del") {
        if (this.chooseRows.length === 0) {
          this.$message.error("请选择要删除的列表行！");
          return false;
        }
        this.dialogTitle = "删除确认";
      }
      this.centerDialogVisible = true;
    },

    // 列表选择状态改变
    selectionChange(val) {
      this.chooseRows = val;
    },

    // 弹窗确定按钮
    ok() {
      if (this.dialogTitle === "删除确认") {
        this.DTLETE_shopShopNoticeLogsDel();
      }
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

/deep/ .tips-info {
  padding: 0 !important;
  margin: 0;
}

.titleBox {
  display: flex;
  align-items: center;
  span {
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
  }
  div {
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
  }
}

.dialogContent {
  width: 100%;
  h4 {
    margin-top: 0;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  div {
    color: #333;
    font-size: 13px;
    line-height: 20px;
  }
}

.delBox {
  text-align: center;
  font-size: 16px;
}
</style>
